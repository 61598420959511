function storageEnabled() {
    try {
        localStorage.setItem("__test", "data");
    } catch (e) {
        if (/QUOTA_?EXCEEDED/i.test(e.name)) {
            return false;
        }
    }
    return true;
}

$(() => {

    if (storageEnabled()) {

        //Detect if browser supports HTML5 storage. Note: IE7 is not supported.
        if (typeof (Storage) !== "undefined") {


            //Dont show the notification box, if the browser has localStorage data with the name "userAcceptsCookies" .
            if ($.cookie('hideNotification'))
                return;

            //Show the notification box
            $(".notification").toggleClass("notification-active");

            //On button click, set local storage and hide the notification box
            $(".notification__close").click(function (e) {

                e.preventDefault();

                // localStorage.setItem('hideNotification', 'yes');
                $.cookie('hideNotification', 1, { domain: "", path: "/" });

                $(".notification").toggleClass("notification-active");
            });
        } else {
            console.log("LocalStorage? ¯\\_(ツ)_/¯");
        }
    }
});